import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Role } from 'src/app/models/user/role.model';
import { MBRDocumentsService } from 'src/app/services/mbr-documents.service';
import { MatDrawer } from '@angular/material/sidenav';
import { IHeaderAction } from '../../components/header-actions/header-actions.interface';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, of, Subscription, switchMap, tap } from 'rxjs';
import { UsersService } from 'src/app/services/users.service';
import { ICardDocument, ICardListInfoData } from '../../components/cards/card-item.interface';
import { PageEvent } from '@angular/material/paginator';
import { sortData } from '../../utils';

@Component({
    selector: 'app-product-mbr-list',
    templateUrl: './product-mbr-list.component.html',
    styleUrls: ['./product-mbr-list.component.scss'],
    standalone: false
})
export class ProductMbrListComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  userRole: Role | undefined;;
  @ViewChild('drawer') drawer: MatDrawer;

  public dataProductMbrs: any[] = [];

  formActions: IHeaderAction[] = [
    {
      id: 'detail',
      title: 'View Detail',
      type: 'stroke',
      icon: 'visibility',
      class: 'primary',
    }
  ];

  pageSettings: {
    defaultPageSize: number,
    pageSizeOptions: number[],
  } = {
    defaultPageSize: 20,
    pageSizeOptions: [20, 50 ,100]
  }
  datePipe = new DatePipe('en-US');
  currentPage: number = 0;
  pageSize: number = this.pageSettings.defaultPageSize;
  searchControl: FormControl = new FormControl('');
  dataToShow: any[];

  subscriptions: Subscription[] = [];
  loadingValidator: boolean;

  currentSortField = {
      sortValue: 'organization',
    };
  currentIsAscending = true;

  sortForm: FormGroup = new FormGroup({
    sortField: new FormControl({
      sortValue: this.currentSortField.sortValue,
    }),
    isAscending: new FormControl(this.currentIsAscending),
  });

  sortOptions = [
    { label: 'Lender', sortValue: 'organization' },
    { label: 'Product', sortValue: 'product' },
  ];

  totalCount: number = 0;
  searchKey: string = '';

  constructor(
    private mbrService: MBRDocumentsService,
    private userService: UsersService,
    private router: Router,
  ) {

  }

  ngOnInit() {
    const userSubscription = this.userService.userRole$.subscribe((role) => {
      this.userRole = role;

      if(this.userRole == Role.Provider) {
        this.formActions = []
      }
    });
    this.subscriptions.push(userSubscription);

    const searchSubscription =this.searchControl.valueChanges
      .pipe(
        debounceTime(300),
        tap(() => {
          this.currentPage = 0;
          this.reload();
        }),
      )
      .subscribe();
    this.subscriptions.push(searchSubscription);

    this.reload();
  }

  onRequestMBR(){
    if(this.isAdmin()){
      this.router.navigate(['/admin/mbr-request/create']);
    }
    else{
      this.router.navigate(['/lender/mbr-request/create']);
    }
  }

  onView(data: any) {
    if(this.isAdmin()){
      this.router.navigate([`/admin/product-mbr/${data.product.id}/${data.organization.id}`]);
    }
    else{
      this.router.navigate([`/lender/product-mbr/${data.product.id}`]);
    }
  }

  close() {
    this.drawer.close();
  }

  isAdmin() {
    return this.userRole == Role.Admin || this.userRole == Role.SystemUser;
  }

  private reload() {
    this.isLoading = true;
    this.searchKey = this.searchControl.value?.toLowerCase() || '';

    const subscription = this.mbrService
    .get({
      sortColumn: this.currentSortField.sortValue,
      sortOrder: this.currentIsAscending ? 'asc' : 'desc',
      page: this.currentPage + 1,
      pageSize: this.pageSize,
      searchKey: this.searchKey
    }).pipe(
      switchMap((response: any) => {
        if (!response.isError) {
          return of(response.data);
        } else {
          return of([]);
        }
      })).subscribe(data => {
        this.dataProductMbrs = data.items;
        this.totalCount = data.totalCount;
        this.dataToShow = this.transformCardData(this.dataProductMbrs)
        this.isLoading = false;
        this.drawer.close();
      }
    );
    this.subscriptions.push(subscription);
  }

  private transformCardData(data: any) {
    const infoFormSubmission: ICardListInfoData[] = data.map(
      (action: any) => {
        let infoData = this.buildInfoData(action);
        return infoData;
      }
    );

    return infoFormSubmission;
  }

  private buildInfoData(
    action: any,
  ): ICardListInfoData {
    let list = [
      {
        label: 'Lender',
        value: action.organization.name
      },
      {
        label: 'Version',
        value: action.mbrDocument?.version ?? '-'
      }
    ]

    const title = action.product.name;

    const documents: ICardDocument[] = [];

    if(action.mbrDocument){
      documents.push({
        label: action.mbrDocument.name,
        value: action.mbrDocument.serverFileName
      });
    }

    return {
      id: action.id,
      list,
      title,
      documents
    } as ICardListInfoData;
  }

  handlePageEvent(event: PageEvent){
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.reload();
  }

  onActionClicked(event: any) {
    const data = this.dataProductMbrs.find(d => d.id == event.id);

    if(!data) return;

    switch (event.actionId) {
      case 'detail':
        this.onView(data);
        break;
      default:
        break;
    }
  }

  setSortField() {
    const { sortField, isAscending } = this.sortForm.value;
    this.currentIsAscending = isAscending;
    this.currentSortField = sortField;

    this.reload();
  }

  resetSortField() {
    this.sortForm.setValue({
      sortField: this.currentSortField,
      isAscending: this.currentIsAscending
    })
  }

  canCreate() {
    return this.userRole !== Role.Provider
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(s => s.unsubscribe());
  }
}
