import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Role } from 'src/app/models/user/role.model';
import { ISelect } from '../../components/mat-multiselect/mat-multiselect.component';
import { MatDrawer } from '@angular/material/sidenav';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IHeaderAction } from '../../components/header-actions/header-actions.interface';
import { debounceTime, of, Subscription, switchMap, tap } from 'rxjs';
import { LenderOrganizationsService } from 'src/app/services/lender.organizations.service';
import { ProductsService } from 'src/app/services/products.service';
import { UsersService } from 'src/app/services/users.service';
import { DeleteConfirmationDialogService } from 'src/app/services/delete-confirmation-dialog.service';
import { Router } from '@angular/router';
import { entityExistsValidator } from 'src/app/validators/entity-exists.validator';
import { ICardListInfoData } from '../../components/cards/card-item.interface';
import {  PageEvent } from '@angular/material/paginator';
import { sortData } from '../../utils';

@Component({
    selector: 'app-lender-organization-list',
    templateUrl: './lender-organization-list.component.html',
    styleUrls: ['./lender-organization-list.component.scss'],
    standalone: false
})
export class LenderOrganizationListComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  userRole: Role | undefined;
  productList: ISelect[] = [];
  @ViewChild('drawer') drawer: MatDrawer;
  isEditing = false;
  id: number;

  public form: FormGroup = new FormGroup({
    name: new FormControl('', {
      validators: [Validators.required],
      updateOn: 'blur',
    }),
    productIds: new FormControl([], { validators: [Validators.required] }),
    description: new FormControl('', { validators: [Validators.required] }),
  });

  public dataLenderOrganizations: any[] = [];

  formActions: IHeaderAction[] = [
    {
      id: 'detail',
      title: 'View Detail',
      type: 'stroke',
      icon: 'visibility',
      class: 'primary',
    },
    {
      id: 'edit',
      title: 'Edit',
      type: 'stroke',
      icon: 'edit',
      class: 'primary',
    },
    {
      id: 'enable',
      title: 'Enable',
      type: 'stroke',
      icon: 'check',
      class: 'success no-text'
    },
    {
      id: 'disable',
      title: 'Disable',
      type: 'stroke',
      icon: 'close',
      class: 'danger no-text'
    }
  ];

  pageSettings: {
    defaultPageSize: number,
    pageSizeOptions: number[],
  } = {
    defaultPageSize: 20,
    pageSizeOptions: [20, 50 ,100]
  }
  datePipe = new DatePipe('en-US');
  currentPage: number = 0;
  pageSize: number = this.pageSettings.defaultPageSize;
  searchControl: FormControl = new FormControl('');
  dataToShow: any[];

  subscriptions: Subscription[] = [];
  loadingValidator: boolean;

  currentSortField = {
    sortValue: '',
  };
  currentIsAscending = true;
  totalCount: number = 0;
  searchKey: string = '';

  sortForm: FormGroup = new FormGroup({
    sortField: new FormControl({
      sortValue: this.currentSortField.sortValue,
    }),
    isAscending: new FormControl(this.currentIsAscending),
  });

  sortOptions = [
    { label: 'Name', sortValue: 'name' },
  ];

  constructor(
    private service: LenderOrganizationsService,
    private productService: ProductsService,
    private userService: UsersService,
    private deleteDialog: DeleteConfirmationDialogService,
    private router: Router,
  ) {

  }

  ngOnInit() {
    const productSuscription = this.productService.get().subscribe((response: any) => {
      if (!response.isError) {
        this.productList = [...response.data.items];
      } else {
        this.productList = [];
      }
    });
    this.subscriptions.push(productSuscription)

    const userSubscription = this.userService.userRole$.subscribe((role) => {
      this.userRole = role;
      if (!this.isAdmin()) {
        this.formActions = []
      }
    });
    this.subscriptions.push(userSubscription);

    const searchSubscription =this.searchControl.valueChanges
      .pipe(
        debounceTime(300),
        tap(() => {
          this.currentPage = 0;
          this.reload();
        }),
      )
      .subscribe();
    this.subscriptions.push(searchSubscription);

    this.reload();
  }

  openRightSidebar(data: any = null) {
    if (data) {
      this.id = data.id;
      this.form.controls['name'].setAsyncValidators([]);

      this.service.getById(this.id.toString()).subscribe(res => {
        if(!res.isError) {
          this.form.reset({ name: res.data.name, description: res.data.description});
          this.form.get('productIds')?.setValue(this.productList.filter(p => {
            return res.data.productIds.some((_p:any) => _p == p.id)
          }));

        }
      })

      this.isEditing = true;
    } else {
      this.form.controls['name'].setAsyncValidators(
        [
          entityExistsValidator(this.service, undefined, (isLoading: boolean) => {
            this.loadingValidator = isLoading;
          })
        ]
      );

      this.isEditing = false;
    }
    this.drawer.toggle();
  }


  popupUpdate(data: any) {
    this.reload();
  }

  onConfirmation(result: any) {
    if (result.confirmed) {
      this.service.delete(result.id).subscribe(() => {
        this.reload();
      });
    }
  }

  onView(data: any) {
    this.router.navigate([`/admin/lenders-list/${data.id}`]);
  }

  close() {
    this.form.reset();
    this.drawer.close();
  }

  onSubmit() {
    if (this.form.valid) {
      this.isLoading = true;
      const data = this.form.value;
      data.productIds = data.productIds.map((prod: any) => prod.id);

      if (this.isEditing) {
        this.service
          .update(this.id.toString(), data)
          .subscribe((data) => {
            this.reload();
          });
      } else {
        this.service.add(this.form.value).subscribe((data) => {
          this.reload();
        });
      }
    }
  }

  isAdmin() {
    return this.userRole == Role.Admin || this.userRole == Role.SystemUser;
  }

  onChangeAvailability(data: any) {
    var deleteSuscription = this.deleteDialog
      .openDeleteConfirmation(
        `This lender organization and all its lenders will be ${data.isActive ? 'disabled' : 'enabled'}.`,
        data.isActive ? 'Disable confirmation' : 'Enable confirmation',
        data.isActive ? 'Disable' : 'Enable'
      )
      .afterClosed()
      .pipe(
        switchMap(dialogClosed => {
          if(dialogClosed)
            return this.service.changeUserAvailability(data.id, !data.isActive);
          else
            return of(false);
        })
      )
      .subscribe((deleteConfirmation) => {
        if (deleteConfirmation) {
          this.reload();
        }
      });

    this.subscriptions.push(deleteSuscription);
  }

  private reload() {
    this.isLoading = true;
    this.searchKey = this.searchControl.value?.toLowerCase() || '';

    const subscription = this.service
      .get({
        sortColumn: this.currentSortField.sortValue,
        sortOrder: this.currentIsAscending ? 'asc' : 'desc',
        page: this.currentPage + 1,
        pageSize: this.pageSize,
        searchKey: this.searchKey
      }).pipe(
      switchMap((response: any) => {
        if (!response.isError) {
          return of(response.data);
        } else {
          return of([]);
        }
      })).subscribe(data => {
        this.dataLenderOrganizations = data.items;
        this.totalCount = data.totalCount;
        this.dataToShow = this.transformCardData(this.dataLenderOrganizations)
        this.isLoading = false;
        this.form.reset();
        this.drawer.close();
      }
    );
    this.subscriptions.push(subscription);
  }

  private transformCardData(data: any) {
    const infoFormSubmission: ICardListInfoData[] = data.map(
      (action: any) => {
        let infoData = this.buildInfoData(action);
        return infoData;
      }
    );

    return infoFormSubmission;
  }

  private buildInfoData(
    action: any,
  ): ICardListInfoData {
    let list = [
      {
        label: 'Description',
        value: action.description
      }
    ]
    const title = action.name;
    return {
      id: action.id,
      list,
      title,
      actions: this.getActionsFor(action)
    } as ICardListInfoData;
  }

  public getActionsFor(form: any): IHeaderAction[] {
    const excludeAction = form.isActive ? 'enable' : 'disable';
    return this.formActions.filter(a => a.id !== excludeAction);
  }

  handlePageEvent(event: PageEvent){
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.reload();
  }

  onActionClicked(event: any) {
    const data = this.dataLenderOrganizations.find(d => d.id == event.id);

    if(!data) return;

    switch (event.actionId) {
      case 'detail':
        this.onView(data);
        break;;
      case 'edit':
        this.openRightSidebar(data);
        break;
      case 'enable':
      case 'disable':
        this.onChangeAvailability(data);
        break
      default:
        break;
    }
  }


  setSortField() {
    const { sortField, isAscending } = this.sortForm.value;
    this.currentIsAscending = isAscending;
    this.currentSortField = sortField;

    this.reload();
  }

  resetSortField() {
    this.sortForm.setValue({
      sortField: this.currentSortField,
      isAscending: this.currentIsAscending
    })
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(s => s.unsubscribe());
  }
}
