import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { Role } from 'src/app/models/user/role.model';
import { ISelect } from '../../components/mat-multiselect/mat-multiselect.component';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { IHeaderAction } from '../../components/header-actions/header-actions.interface';
import { debounceTime, of, Subscription, switchMap, tap } from 'rxjs';
import { UsersService } from 'src/app/services/users.service';
import { DeleteConfirmationDialogService } from 'src/app/services/delete-confirmation-dialog.service';
import { Router } from '@angular/router';
import { ICardListInfoData } from '../../components/cards/card-item.interface';
import {  MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { AdministratorService } from 'src/app/services/administrator.service';
import { RightSidebarContainerComponent } from '../../components/right-sidebar-container/right-sidebar-container.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SearchSortComponent } from '../../components/search-sort/search-sort.component';
import { MatIconModule } from '@angular/material/icon';
import { CardGridItemComponent } from '../../components/cards/card-grid-item/card-grid-item.component';
import { LoaderComponent } from '../../components/loader/loader.component';
import { EmptyDataComponent } from '../../components/empty-data/empty-data.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-administrator-list',
    templateUrl: './administrator-list.component.html',
    styleUrls: ['./administrator-list.component.scss'],
    imports: [
      CommonModule,
      MatSidenavModule,
      RightSidebarContainerComponent,
      FormsModule,
      MatInputModule,
      MatButtonModule,
      ReactiveFormsModule,
      MatFormFieldModule,
      SearchSortComponent,
      MatIconModule,
      CardGridItemComponent,
      LoaderComponent,
      MatPaginatorModule,
      EmptyDataComponent
    ],
    standalone: true
})
export class AdministratorListComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  userRole: Role | undefined;
  productList: ISelect[] = [];
  @ViewChild('drawer') drawer: MatDrawer;
  isEditing = false;
  id: number;

  public form: FormGroup = new FormGroup({
    name: new FormControl('', {
      validators: [Validators.required],
      updateOn: 'blur',
    }),
    description: new FormControl('', { validators: [Validators.required] }),
  });

  public dataAdministrator: any[] = [];

  formActions: IHeaderAction[] = [
    {
      id: 'edit',
      title: 'Edit',
      type: 'stroke',
      icon: 'edit',
      class: 'primary',
    },
    {
      id: 'enable',
      title: 'Enable',
      type: 'stroke',
      icon: 'check',
      class: 'success no-text'
    },
    {
      id: 'disable',
      title: 'Disable',
      type: 'stroke',
      icon: 'close',
      class: 'danger no-text'
    }
  ];

  pageSettings: {
    defaultPageSize: number,
    pageSizeOptions: number[],
  } = {
    defaultPageSize: 20,
    pageSizeOptions: [20, 50 ,100]
  }
  datePipe = new DatePipe('en-US');
  currentPage: number = 0;
  pageSize: number = this.pageSettings.defaultPageSize;
  searchControl: FormControl = new FormControl('');
  dataToShow: any[];

  subscriptions: Subscription[] = [];
  loadingValidator: boolean;

  currentSortField = {
    sortValue: '',
  };
  currentIsAscending = true;
  totalCount: number = 0;
  searchKey: string = '';

  sortForm: FormGroup = new FormGroup({
    sortField: new FormControl({
      sortValue: this.currentSortField.sortValue,
    }),
    isAscending: new FormControl(this.currentIsAscending),
  });

  sortOptions = [
    { label: 'Name', sortValue: 'name' },
  ];

  constructor(
    private service: AdministratorService,
    private userService: UsersService,
    private deleteDialog: DeleteConfirmationDialogService,
    private router: Router,
  ) {

  }

  ngOnInit() {
    const userSubscription = this.userService.userRole$.subscribe((role) => {
      this.userRole = role;
      if (!this.isAdmin()) {
        this.formActions = []
      }
    });
    this.subscriptions.push(userSubscription);

    const searchSubscription =this.searchControl.valueChanges
      .pipe(
        debounceTime(300),
        tap(() => {
          this.currentPage = 0;
          this.reload();
        }),
      )
      .subscribe();
    this.subscriptions.push(searchSubscription);

    this.reload();
  }

  openRightSidebar(data: any = null) {
    if (data) {
      this.id = data.id;
      this.form.get('name')?.setValue(data.name);
      this.form.get('description')?.setValue(data.description);

      this.isEditing = true;
    } else {
      this.form.get('name')?.setValue("");
      this.form.get('description')?.setValue("");
      this.isEditing = false;
    }
    this.drawer.toggle();
  }


  onConfirmation(result: any) {
    if (result.confirmed) {
      this.service.delete(result.id).subscribe(() => {
        this.reload();
      });
    }
  }

  close() {
    this.form.reset();
    this.drawer.close();
  }

  onSubmit() {
    if (this.form.valid) {
      this.isLoading = true;
      const data = this.form.value;

      if (this.isEditing) {
        this.service
          .update(this.id.toString(), data)
          .subscribe((data) => {
            this.reload();
          });
      } else {
        this.service.add(this.form.value).subscribe((data) => {
          this.reload();
        });
      }
    }
  }

  isAdmin() {
    return this.userRole == Role.Admin || this.userRole == Role.SystemUser;
  }

  onChangeAvailability(data: any) {
    var deleteSuscription = this.deleteDialog
      .openDeleteConfirmation(
        `This administrator will be ${data.isActive ? 'disabled' : 'enabled'}.`,
        data.isActive ? 'Disable confirmation' : 'Enable confirmation',
        data.isActive ? 'Disable' : 'Enable'
      )
      .afterClosed()
      .pipe(
        switchMap(dialogClosed => {
          if(dialogClosed)
            return this.service.changeUserAvailability(data.id, !data.isActive);
          else
            return of(false);
        })
      )
      .subscribe((deleteConfirmation) => {
        if (deleteConfirmation) {
          this.reload();
        }
      });

    this.subscriptions.push(deleteSuscription);
  }

  private reload() {
    this.isLoading = true;
    this.searchKey = this.searchControl.value?.toLowerCase() || '';

    const subscription = this.service
      .get({
        sortColumn: this.currentSortField.sortValue,
        sortOrder: this.currentIsAscending ? 'asc' : 'desc',
        page: this.currentPage + 1,
        pageSize: this.pageSize,
        searchKey: this.searchKey
      }).pipe(
      switchMap((response: any) => {
        if (!response.isError) {
          return of(response.data);
        } else {
          return of([]);
        }
      })).subscribe(data => {
        this.dataAdministrator = data.items;
        this.totalCount = data.totalCount;
        this.dataToShow = this.transformCardData(this.dataAdministrator)
        this.isLoading = false;
        this.form.reset();
        this.drawer.close();
      }
    );
    this.subscriptions.push(subscription);
  }

  private transformCardData(data: any) {
    const infoFormSubmission: ICardListInfoData[] = data.map(
      (action: any) => {
        let infoData = this.buildInfoData(action);
        return infoData;
      }
    );

    return infoFormSubmission;
  }

  private buildInfoData(
    action: any,
  ): ICardListInfoData {
    let list = [
      {
        label: 'Description',
        value: action.description
      }
    ]
    const title = action.name;
    return {
      id: action.id,
      list,
      title,
      actions: this.getActionsFor(action)
    } as ICardListInfoData;
  }

  public getActionsFor(form: any): IHeaderAction[] {
    const excludeAction = form.isActive ? 'enable' : 'disable';
    return this.formActions.filter(a => a.id !== excludeAction);
  }

  handlePageEvent(event: PageEvent){
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.reload();
  }

  onActionClicked(event: any) {
    const data = this.dataAdministrator.find(d => d.id == event.id);

    if(!data) return;

    switch (event.actionId) {
      case 'edit':
        this.openRightSidebar(data);
        break;
      case 'enable':
      case 'disable':
        this.onChangeAvailability(data);
        break
      default:
        break;
    }
  }


  setSortField() {
    const { sortField, isAscending } = this.sortForm.value;
    this.currentIsAscending = isAscending;
    this.currentSortField = sortField;

    this.reload();
  }

  resetSortField() {
    this.sortForm.setValue({
      sortField: this.currentSortField,
      isAscending: this.currentIsAscending
    })
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(s => s.unsubscribe());
  }
}
